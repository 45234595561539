const config = {
  dev: {
    // api:'http://192.168.0.106:8084/admin/api'
    api: "https://api.alphant.co/admin/api",
    // api: "https://test-api.alphant.co/admin/api",
  },
  test: {
    api: "https://test-api.alphant.co/admin/api",
  },
  pro: {
    api: "https://api.alphant.co/admin/api",
  },
};

export default {
  ENV: process.env.VUE_APP_DOMAIN_CONFIG,
  ...config[process.env.VUE_APP_DOMAIN_CONFIG],
};
